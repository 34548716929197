import React, { Fragment } from "react"
import { navigate } from "gatsby"
import firebase from "firebase"
import axios from "axios"
import {
  GATSBY_ENV,
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_SUBDOMAIN,
  GATSBY_UPDATE_MOBILE_NUMBER_WEBHOOK,
} from "gatsby-env-variables"

import { getSignedInUser, hasRoles } from "./user"
import { handleSignOut } from "./signout"
import { parseAuthErrorMessage } from "./errors"
import {
  getUserAddresses,
  getUserDataByMobileNumber,
  sendOTP,
  handleVerifyName,
  handleEmailLogin,
  changeProvider
} from "./signin"
import { checkIfEmailAlreadyExists } from "./signup"

import { initialState } from "../../../context/initialState"
import { isBrowser } from "../../../services/general"
import { generateJWT } from "../../../services/jwt"
import { firebaseApi } from "../../../services/firebaseApi"
import {
  generateUploadFiles,
  zendeskApi,
  zendeskUploadFiles,
} from "../../../services/zendeskService"
import { changeMobileNumberZendeskTemplate } from "../utils/changeMobileNumberZendeskTemplate"

const compareBirthday = ({ birthday }) => {
  let userData = getSignedInUser()?.userData
  let isSameMonth = userData?.birthday?.month === birthday?.month?.value
  let isSameDay = userData?.birthday?.date === birthday?.date?.value
  let isSameYear = userData?.birthday?.year === birthday?.year?.value

  return !!isSameMonth && !!isSameDay && !!isSameYear
}

const generateZendeskRequestTemplate = ({ values }) => {
  const TEST_ENV = GATSBY_ENV === "production" ? "" : "[TEST] "
  let tags = ["change_mobile_number", "pulsecare"]
  const subject = `Change mobile number request from ${values?.oldMobileNumber}`
  if (GATSBY_ENV !== "production") tags.push("test")

  let newValues = { ...values }

  delete newValues.documents

  return {
    request: {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: "",
        email: values?.email || "it@medgrocer.com",
      },
      comment: {
        body: changeMobileNumberZendeskTemplate({ values }),
      },
      tags,
    },
  }
}

export const handleAuthSubmit = async ({ payload, state, dispatch }) => {
  let { setLoading, setMessage, otpVerifier, authDispatch } = payload
  let mobileNumber = payload?.values?.mobileNumber
  let email = payload?.values?.email
  let otp = payload?.values?.otp
  mobileNumber = `+63${mobileNumber}`

  console.log("payload: ", payload)

  const handleSuccessCallback = () => {
    payload.resetForm()
    dispatch(initialState)
    setLoading(false)
    navigate("/update-number/complete")
  }

  const handleErrorCallback = () => {
    setLoading(false)
    setMessage({
      type: "danger",
      content: (
        <p className="is-size-6">
          There was an error with your request, please try again.
        </p>
      ),
    })
  }

  switch (payload.module) {
    case "sign-in":
      handleEmailLogin({
        ...payload,
        callBack: () => {
          setLoading(false)
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break
    case "sign-in-mobile":
      setLoading(true)
      let tempOtpVerifier = await sendOTP({
        ...payload,
        mobileNumber,
        email,
      })
      setLoading(false)
      authDispatch({
        type: "SAVE_DETAILS",
        payload: {
          otpVerifier: tempOtpVerifier,
          mobileNumber: payload?.values?.mobileNumber,
          email: payload?.values?.email,
          password: payload?.values?.password,
        },
      })
      //payload.resetForm()
      if(tempOtpVerifier) navigate("/verify-otp")
      break
    case "verify-email":
        let { email } = payload.values
        console.log("sign up email: ", email)   
        const IS_ALREADY_ENROLLED = await checkIfEmailAlreadyExists(email)

        dispatch({
          type: "SAVE_AUTH",
          payload: { email, hasBeenVerified: true },
        })
  
        if (IS_ALREADY_ENROLLED) {
          navigate("/sign-in")
        } else {
          console.log("nav 1")
          navigate("/patient/enroll")
        }
        setLoading(false)
      break
    case "verify-name":
      handleVerifyName({
        ...payload,
        callBack: () => {
          setLoading(false)
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break
    case "verify-otp":
      try {
        let response = await otpVerifier?.confirm(otp)
        console.log("Here in auth")
        changeProvider({
          ...payload,
          callBack: () => {
            setLoading(false)
            setMessage({
              content:
                "Successfully Set Password.",
            })
          },
          errorCallback: () => {
            setLoading(false)
          },
        })

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

        let authUid = response?.user?.uid || ""
        let mobileNumber = response?.user?.phoneNumber

        // let userData = await getUserData({ authUid })

        // if (!userData) {
        let userData = await getUserDataByMobileNumber({
          authUid,
          mobileNumber,
        })
        // }

        let addressesId = userData?.addresses
        let userAddresses = await getUserAddresses({ addressesId })

        if (isBrowser()) {
          sessionStorage.setItem("userData", JSON.stringify({ ...userData }))
          sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
        }

        setLoading(false)

        // Navigation logic
        const HAS_USER_DOCUMENT = !!userData?.id

        // Happens if the there is no user found in the database
        if (!HAS_USER_DOCUMENT) {
          navigate("/patient/enroll")
          return
        }

        // Checks the status of the user and navigates accordingly
        const { status } = userData.roles[0]

        const STATUS = {
          DEACTIVATED: status === "deactivated",
          ACTIVE: status === "active",
          INACTIVE: status === "inactive",
        }

        switch (true) {
          case STATUS.DEACTIVATED:
            navigate("/update-number")
            return
          case STATUS.ACTIVE:
            //add new field to user doc and update it to say that user has reauthenticated
            try {
              const userRef = await firebase.firestore().collection('users').doc(userData?.id);
              console.log("user ref", userRef)
              await userRef.update({
                hasTwoProviders: true,
              });
              console.log('Field added/updated successfully');
            } catch (error) {
              console.log('Error updating document: ', error);
            }
            navigate("/")
            return
        }
        const HAS_UPLOADED_VALID_ID = !!userData?.hasUploadedValidID
        // Happens when the user's ID is valid but the enrollment is still being processed
        if (HAS_UPLOADED_VALID_ID) {
          setMessage({
            type: "success",
            content: (
              <Fragment>
                Your enrollment is currently being processed and{" "}
                <b>will be ready in one working day</b>. Once your account is
                activated, you will be able to order your medicines using this
                platform.
              </Fragment>
            ),
          })
          authDispatch({
            type: "DISABLE_BUTTON",
            payload: true,
          })
          handleSignOut({ redirect: false })
          return
        }

        const PENDING_VALID_ID = !userData?.noValidIDAfter12Hours

        // Happens when the user's ID is invalid and it is less than 12 hours since submission
        if (PENDING_VALID_ID) {
          setMessage({
            type: "warning",
            content: (
              <Fragment>
                Your enrollment cannot be processed for now because the ID you
                uploaded is invalid. Please send a clear copy of the patient's
                valid ID to pulsecare@medgrocer.com within 12 hours so that we
                can re-process your enrollment. If you need more than 12 hours,
                please re-enroll through pulsecare.medgrocer.com.
              </Fragment>
            ),
          })
          authDispatch({
            type: "DISABLE_BUTTON",
            payload: true,
          })
          handleSignOut({ redirect: false })
          return
        }
        // Happens when the user has still not submitted a valid ID after 12 hours
        navigate("/patient/enroll")
        return
      } catch (error) {
        setLoading(false)
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message: parseAuthErrorMessage(error.message, {
              authDispatch,
              mobileNumber,
              setMessage,
            }),
          },
        })
      }
      break

    case "verify-birthday":
      if (state?.incorrectBirthdayAttempt) {
        setLoading(false)
        window.location.href =
          "mailto:pulsecare@medgrocer.com?subject=Forgotten Birthday"
        authDispatch({
          type: "SET_INCORRECT_BIRTHDAY_ATTEMPT",
          payload: false,
        })
      } else {
        let userData = getSignedInUser()?.userData
        if (compareBirthday({ birthday: payload?.values?.birthday }))
          if (hasRoles()) {
            if (isBrowser()) {
              sessionStorage.setItem(
                "userData",
                JSON.stringify({ ...userData, verified: "birthday" })
              )
            }
            navigate("/epharmacy/order")
          } else {
            handleSignOut({ redirect: false })
            setMessage({
              type: "success",
              content: (
                <Fragment>
                  Your enrollment is currently being processed and{" "}
                  <b>will be ready in one working day</b>. Once your account is
                  activated, you will be able to order your medicines using this
                  platform.
                </Fragment>
              ),
            })
            setLoading(false)
            authDispatch({
              type: "DISABLE_BUTTON",
              payload: true,
            })
          }
        else {
          setMessage({
            type: "danger",
            content: (
              <Fragment>
                The birthday you input did not match our records. Please input
                the birthday you placed during enrollment. If you are unable to
                remember your birthday, please email your valid ID to
                pulsecare@medgrocer.com with the subject, "Forgotten Birthday".
                We will reach out to you once we have updated our records so you
                can sign in.
              </Fragment>
            ),
          })
          authDispatch({
            type: "SET_INCORRECT_BIRTHDAY_ATTEMPT",
            payload: true,
          })
          let newSessionDocument = {
            created: new Date(),
            programCode: "NSX1PAPPC",
            birthday: `${userData?.birthday?.month} ${userData?.birthday?.date}, ${userData?.birthday?.year}`,
            formBirthday: `${payload?.values?.birthday?.month?.value} ${payload?.values?.birthday?.date?.value}, ${payload?.values?.birthday?.year?.value}`,
            mobileNumber: userData?.mobileNumber,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
          }
          await firebase
            .firestore()
            .collection("sessions")
            .add({ ...newSessionDocument })
          setLoading(false)
        }
      }
      break

    case "update-number":
      let oldMobileNumber = `+63${payload?.values?.oldMobileNumber}`
      let newMobileNumber = `+63${payload?.values?.newMobileNumber}`

      setMessage(null)
      const JWT_OBJECT = await generateJWT()
      const ACCESS_TOKEN = JWT_OBJECT?.data?.access_token

      let oldMobileNumberRequest = await firebaseApi({
        accessToken: ACCESS_TOKEN,
      }).post(`:runQuery`, {
        structuredQuery: {
          select: {
            fields: [],
          },
          from: [
            {
              collectionId: "users",
            },
          ],
          where: {
            fieldFilter: {
              field: {
                fieldPath: "mobileNumber",
              },
              op: "EQUAL",
              value: {
                stringValue: oldMobileNumber,
              },
            },
          },
        },
      })

      let newMobileNumberRequest = await firebaseApi({
        accessToken: ACCESS_TOKEN,
      }).post(`:runQuery`, {
        structuredQuery: {
          select: {
            fields: [],
          },
          from: [
            {
              collectionId: "users",
            },
          ],
          where: {
            fieldFilter: {
              field: {
                fieldPath: "mobileNumber",
              },
              op: "EQUAL",
              value: {
                stringValue: newMobileNumber,
              },
            },
          },
        },
      })

      let oldMobileNumberID =
        oldMobileNumberRequest?.data[0]?.document?.name || ""

      let newMobileNumberID =
        newMobileNumberRequest?.data[0]?.document?.name || ""

      // if (!!oldMobileNumberID && !newMobileNumberID) {
      if (!!oldMobileNumberID) {
        let mgcareZendeskConfig = {
          email: GATSBY_ZENDESK_EMAIL,
          apiKey: GATSBY_ZENDESK_API_KEY,
          apiUrl: GATSBY_ZENDESK_SUBDOMAIN,
        }

        let requestTemplate = generateZendeskRequestTemplate({
          values: payload?.values,
        })

        const uploadedFiles = await zendeskUploadFiles(
          [...payload?.values?.documents?.documents],
          null,
          mgcareZendeskConfig
        )

        const generatedFileToken = await generateUploadFiles(uploadedFiles)
        const comment = {
          ...requestTemplate.request.comment,
          uploads: generatedFileToken,
        }
        requestTemplate = { request: { ...requestTemplate.request, comment } }

        const response = await zendeskApi({ ...mgcareZendeskConfig }).post(
          "/requests.json",
          requestTemplate
        )

        setLoading(false)

        setMessage({
          type: "success",
          content: (
            <p className="is-size-6">
              Your request has been sent. A Pulse Care coordinator will review
              your provided information and will send your registered mobile
              number via SMS within one working day.
            </p>
          ),
        })

        try {
          await axios.post(GATSBY_UPDATE_MOBILE_NUMBER_WEBHOOK, {
            ...response.data.request,
            oldMobileNumber,
          })
          handleSuccessCallback()
        } catch {
          handleErrorCallback()
        }

        break
      } else {
        setLoading(false)
        if (!oldMobileNumberID) {
          setMessage({
            type: "danger",
            content: (
              <p className="is-size-6">
                This mobile number is not used for any existing enrollment.
                Place the mobile number you used upon your enrollment. If you
                need assistance, email us at pulsecare@medgrocer.com.
              </p>
            ),
          })
        }
        // else if (newMobileNumberID) {
        //   setMessage({
        //     type: "danger",
        //     content: (
        //       <p className="is-size-6">
        //         This mobile number is already a registered MedGrocer account.
        //         Please sign in using these credentials. If you need assistance,
        //         email us at pulsecare@medgrocer.com.
        //       </p>
        //     ),
        //   })
        // }
      }

    default:
      break
  }
}
